import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "cookbook-projects"
    }}>{`Cookbook projects`}</h1>
    <p>{`👋 this section started as a month-long series of almost daily data
visualizations. Originally structured as a challenge+solution, I warmly
recommend you approach it that way as well.`}</p>
    <p>{`Look at each section, get the data, try to build it yourself. Then look at my
solution. Each comes with a recording of a livestream I did while figuring out
how to solve the problem :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      